import React from 'react';
import { contactFormFields } from './contact-form-fields';
import { Form, Modal } from '@components';
import { useSiteData, DataContextProps } from '@context';

/**
 * Contact Form Button.
 */
const ContactFormButton = (): JSX.Element | null => {
  const { siteData }: DataContextProps = useSiteData();
  if (siteData) {
    const { translations } = siteData;

    /**
     * Render.
     */
    return (
      <Modal buttonText={translations?.contact_us}>
        <Form
          formFields={contactFormFields}
          formTitle={translations?.contact_us}
          formIntro={translations?.contact_form_intro}
          buttonText={translations?.submit}
          formId="footer-contact-form"
        />
      </Modal>
    );
  }
  return null;
};
export default ContactFormButton;
