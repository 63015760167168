import React, { ReactNode, useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Location, UITranslations } from '@interfaces';
import { DataProvider, StateProvider } from '@context';
import { getCurrentTranslatedBlobs } from '@utilities';
import {
  BackToTop,
  Footer,
  Header,
  StorybookButton,
  TopHeaderBar,
  Transitions,
  setupGlossaryLinks,
  termLookup,
} from '@components';

/**
 * Layout.
 *
 * @param {Object} children
 *   Object of children react components.
 * @param {string} language
 *   Current language code.
 */
const Layout: React.FC<{
  children: ReactNode;
  pageContext: { language: string; slug: string; pageType: string };
  location: Location;
}> = ({ children, location, pageContext }) => {
  const data = useStaticQuery(graphql`
    query SiteDataQuery {
      blobs: allMarkdownRemark(
        filter: { frontmatter: { blobs: { eq: true } } }
      ) {
        edges {
          node {
            id
            frontmatter {
              title
              language
              blobs
              cosmo_for_kids
              treatments
              radiation_therapy
              proton_therapy
              radiosurgery
              brachytherapy
              learn_about
              patient_stories
              all_patient_stories
              avm
              cancer_types
              brain_cancer
              breast_cancer
              head_and_neck_cancer
              lung_cancer
              prostate_cancer
              rectal_cancer
              spinal_cancer
              thoracic_cancer
              welcome
              skip_to_main_content
              treatment_locator
              faqs
              glossary
              read_more
              learn_more
              share_your_story
              share_your_story_description
              share_your_story_title
              share_story_disclaimer
              first_name
              last_name
              email
              submit
              story
              intended_use
              intended_use_description
              interventional_solutions
              bone_metastasis
              safety
              safety_description
              privacy_policy
              english
              japanese
              portuguese
              simplified_chinese
              traditional_chinese
              finnish
              german
              spanish
              patient_quote
              related_content
              related_stories
              main_navigation
              back_to_home
              safety_information
              contact_us
              about
              pancreatic_cancer
              pediatric_cancer
              sarcoma
              skin_cancer
              stomach_cancer
              kidney_cancer
              liver_cancer
              lymphoma
              metastatic_cancer
              anal_cancer
              bladder_cancer
              endometrial_cancer
              esophagus_cancer
              brain_tumors
              cervical_cancer
              colorectal_cancer
              resources
              educational_videos
              noona_app
              patient_guides
              questions_to_ask_your_doctor
              external_beam_radiation_therapy
              interventional_oncology
              thank_you
              successful_submission
              contact_form_intro
              close
              trademarks
              download_faq
              search
              no_results
              start_typing
              search_results
              showing
              results
              of
            }
          }
        }
      }
      legacyPages: allMarkdownRemark(
        filter: { frontmatter: { template: { eq: "basic-page" } } }
        sort: { frontmatter: { title: ASC } }
      ) {
        edges {
          node {
            id
            frontmatter {
              id
              title
              subtitle
              teaser
              path
              template
              language
              read_more_button
              quote
              content_type
              node_type
              banner_image {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
              gradient_direction
              gradient_color_a
              gradient_color_b
              title_color
              subtitle_color
              keywords
              faqs_list
              related_pages
              glossary_landing_page
              video_id
            }
          }
        }
      }
      empowerMe: allMdx {
        nodes {
          id
          body
          frontmatter {
            id
            language
            path
            title
            teaser
            template
            node_type
            content_type
            cancer_type
            simple_banner_image {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
            content_banner_image {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
            page_image {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
            carousel_image {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
            featured_banner_image {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  `);

  const { language, slug, pageType } = pageContext;
  const translations: UITranslations = getCurrentTranslatedBlobs(
    data,
    language
  );

  /**
   * Setup glossary links on pages.
   */
  useEffect(() => {
    setTimeout(() => {
      setupGlossaryLinks();
      termLookup();
    }, 100);
  }, [slug]);

  /**
   * Render.
   */
  return (
    <DataProvider
      translations={translations}
      language={language}
      legacyPages={data.legacyPages}
      empowerMePages={data.empowerMe}
      pageType={pageType}
    >
      <StateProvider>
        <div id="consent-blackbar" />
        <a href="#primary-content" className="visually-hidden">
          {translations.skip_to_main_content}
        </a>
        <div className={`layout-wrapper`}>
          <TopHeaderBar pageType={pageType} />
          <Header />
          <div className="layout-container">
            <Transitions location={location}>{children}</Transitions>
          </div>
          <Footer />
          <BackToTop />
          <StorybookButton />
        </div>
      </StateProvider>
    </DataProvider>
  );
};
export default Layout;
