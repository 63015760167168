import React, { ReactNode } from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import { Ripple } from '@base-components';

/**
 * Modal.
 */
const Modal: React.FC<{
  children: ReactNode;
  title?: string;
  buttonText: string;
  primary?: boolean;
  large?: boolean;
}> = ({ children, title, buttonText, primary = true, large = false }) => (
  <Dialog.Root>
    <Dialog.Trigger asChild>
      <button className={primary ? 'primary' : 'secondary'} type="button">
        {buttonText}
        <Ripple />
      </button>
    </Dialog.Trigger>
    <Dialog.Portal>
      <Dialog.Trigger asChild>
        <Dialog.Overlay className="dialog-overlay" />
      </Dialog.Trigger>
      <Dialog.Content
        className={`dialog-content-wrapper ${large ? 'wide' : ''}`}
      >
        <Dialog.Close className="dialog-close-button">Close</Dialog.Close>
        {title && <Dialog.Title className="dialog-title">{title}</Dialog.Title>}
        <div className="dialog-content">{children}</div>
      </Dialog.Content>
    </Dialog.Portal>
  </Dialog.Root>
);
export default Modal;
