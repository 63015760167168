import React, { useEffect, useState } from 'react';
import { useWindowSize } from '@hooks';

/**
 * Storybook Button.
 */
const StorybookButton = (): JSX.Element | null => {
  const [showButton, setShowButton] = useState<boolean>(false);
  const { width } = useWindowSize();

  /**
   * Show button if in a dev, staging or local environment.
   */
  useEffect(() => {
    const currentEnvironment = window.location.origin.toLowerCase();
    if (
      ['localhost', 'dev', 'staging', 'localization'].some((domain) =>
        currentEnvironment.includes(domain)
      )
    ) {
      setShowButton(true);
    }
  }, []);

  /**
   * Open storybook.
   */
  const openStorybook = (): void => {
    const currentEnvironment = window.location.origin.toLowerCase();
    const storybookPath = currentEnvironment.includes('localhost')
      ? 'http://localhost:6006/'
      : '/storybook/';
    window.open(storybookPath, '_blank');
  };

  /**
   * Render.
   */

  return showButton && width > 768 ? (
    <button
      type="button"
      className="storybook-button"
      onClick={openStorybook}
    ></button>
  ) : null;
};
export default StorybookButton;
