/* eslint-disable no-useless-escape */

import striptags from 'striptags';
import { SiteDataProps } from '@context';
import { PageData, Faqs } from '@interfaces';

/**
 * Get Safe Name.
 *
 * @param {string} name String that needs to be made 'safe' (no parenthesis,
 * spaces become dashes, lowercase).
 * @return {string} Name converted to be 'safe'.
 */

export const getSafeName = (name: string): string => {
  if (name) {
    let safeName = name.replace(new RegExp(/\(([^()]+)\)/g), '');
    safeName = safeName.toLowerCase();
    safeName = safeName.replace(new RegExp(/\s/g), '-');
    if (safeName.slice(-1) === '-') {
      safeName = safeName.slice(0, -1);
    }
    return safeName;
  }
  return '';
};

/**
 * Get break space index. Used for adding ellipses after spaces are found.
 *
 * @param {string} content
 * @param {number} textLength
 * @returns {number | null}
 */
export const getBreakSpaceIndex = (
  content: string,
  textLength: number
): number => {
  if (content.length > textLength) {
    const subContent = content.substring(textLength);
    return subContent.indexOf(' ');
  }
  return 0;
};

/**
 * Get safe teaser text that has proper ellipses so no word is broken part way.
 *
 * @param {string} teaserText
 * @param {number} teaserLength
 * @returns {number | null}
 */
export const getSafeTeaser = (
  teaserText: string,
  teaserLength: number
): string => {
  if (teaserText && teaserText !== '') {
    let safeContent = striptags(teaserText).trim();
    const breakSpaceIndex = getBreakSpaceIndex(safeContent, teaserLength);
    if (safeContent.length > teaserLength && breakSpaceIndex > 0) {
      const breakSpaceContent = `${safeContent.slice(
        0,
        teaserLength + breakSpaceIndex
      )}`;
      return breakSpaceContent.slice(-1) === '.'
        ? `${breakSpaceContent}..`
        : `${breakSpaceContent}...`;
    }
    return safeContent;
  }
  return '';
};

/**
 * Get subheading text.
 *
 * @param {string} title Page title.
 * @param {string} subtitle Page subtitle.
 * @param {string} nodeType Page type and first URL argument.
 * @param {string | null} contentType Content type and second URL argument..
 * @return {string}
 */
export const getSubheadingText = (
  title: string,
  subtitle: string,
  nodeType: string,
  contentType: string,
  siteData: SiteDataProps
): string => {
  if (siteData) {
    const { translations } = siteData;
    let subheading = subtitle;
    if (nodeType && nodeType !== 'patient-stories') {
      const safeContentType = contentType?.replace(new RegExp('-', 'g'), '_');
      if (safeContentType && translations) {
        subheading = translations[safeContentType] ?? '';
        subheading = subheading === title ? '' : subheading;
        return subheading;
      }
    }
  }
  return '';
};

/**
 * Get Banner Subtitle.
 *
 * @param {string} title Page title.
 * @param {string} nodeType Page type and first URL argument.
 * @param {string} contentType Content type and second URL argument.
 * @param {Object} translatedBlobText UI interface translations.
 * @returns {string}
 */
export const getBannerSubtitle = (
  nodeType: string,
  contentType: string,
  title: string,
  siteData: SiteDataProps
): string => {
  let subtitle = '';
  if (nodeType && contentType && siteData) {
    const { translations } = siteData;
    const safeNodeType = nodeType.replace(new RegExp('-', 'g'), '_');
    const safeContentType = contentType.replace(new RegExp('-', 'g'), '_');
    subtitle =
      safeNodeType === 'patient_stories'
        ? translations[safeNodeType]
        : translations[safeContentType];

    subtitle = subtitle === title ? '' : subtitle;
  }
  return subtitle;
};

/**
 * Get Safe Title
 *
 * @param {string} title
 *   Current language title that needs to be found.
 * @param {Object} translations
 *   Current language blob text.
 *
 * @return {string}
 *   Title string from blob text.
 */
export const getSafeTitle = (
  titleKey: string,
  siteData: SiteDataProps
): string | boolean => {
  if (siteData) {
    const { translations } = siteData;
    if (titleKey !== null) {
      const safeKey = titleKey.replace(new RegExp('-', 'g'), '_');
      return translations ? translations[safeKey] : '';
    }
  }
  return '';
};

/**
 * Get language name.
 *
 * @param {string} languageCode
 *   Current locale code.
 *
 * @return {string}
 *   Full language name.
 */
export const getLanguageName = (languageCode: string): string => {
  let languageName = 'english';
  switch (languageCode) {
    case 'ja':
      languageName = 'japanese';
      break;
    case 'pt-br':
      languageName = 'portuguese';
      break;
    case 'es-xl':
      languageName = 'spanish';
      break;
    default:
      break;
  }
  return languageName;
};

/**
 * Get Translated Blobs.
 *
 * @param {Object} data Data object of all of the translated blob text.
 * @param {string} language Current language code.
 * @return {Object} Object of all current langauge translated blobs.
 */
export const getCurrentTranslatedBlobs = (data: PageData, language: string) => {
  let translations = {};
  data.blobs.edges.forEach((blob) => {
    if (blob.node.frontmatter.language === language) {
      translations = blob.node.frontmatter;
    }
  });
  return translations;
};

/**
 * Get Translated FAQs.
 *
 * @param {Object} data Faqs data object of all of the questions.
 * @param {string} language Current language code.
 * @return {Object} Object of all current questions.
 */
export const getCurrentLanguageQuestions = (data: Faqs, language: string) => {
  let questions = {};
  data?.faqsList?.edges?.forEach((faq) => {
    if (faq.node.frontmatter.language === language) {
      questions = faq.node.frontmatter;
    }
  });
  return questions;
};

/**
 * Is Mobile Device.
 *
 * @returns bool
 */
export const isMobileDevice = (): boolean => {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
    // @ts-ignore
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};
